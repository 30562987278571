* {
    font-family: 'Montserrat', sans-serif;
}
/* Dates.js css */
.quote-container {
    /* background-color: grey; */
    max-height: 300px;
    min-width: 100%;
    /* padding: 5px 5px 5px 5px;  */
}
.quote-calculations {
    padding-top: 5px;
}
.quote-content  {
    /* padding: 10px 10px 10px 10px; */
    /* background-color: #ece9e9; */
    min-height: 200px; 
}
.quote-summary {
    /* background-color: red; */
    border-color: #dfdfdf;
    border-style: solid;
    border-width: 1px;
    padding: 5px;
}
.container > h1 {
    font-weight: 'Montserrat', sans-serif;
    font-weight: 900;
}

.valet-time-container {
    display: inline-block;
    padding-left: 10px;
    padding-right: 10px;
    width: 50%;
}
.return-time-container {
    display: inline-block;
    padding-left: 10px;
    padding-right: 10px;
    width: 50%;
}
.airline-container {
    padding: 10px;
}
.pricing-left{
    float:left;
    width:75%;
    overflow:hidden;
    padding-left: 6px;
    font-family: 'Montserrat', sans-serif;
    font-weight: 200;
    padding-top: .5rem;
    padding-bottom: .5rem;
    border-bottom: 1px solid #e4e4e4;
}
.pricing-message-return {
    color: black;
    float:left;
    width:75%;
    overflow:hidden;
    padding-left: 6px;
    padding-top: 1rem;
    /* padding-bottom: .5rem; */
    font-size: 18px;
}
.pricing-total {
    color: black;
    text-align: right;
    float:left;
    width:25%;
    overflow:hidden;
    padding-top: 1rem;
    /* padding-bottom: .5rem; */
    font-size: 18px;
}
.pricing-total-return {
    color: green;
    text-align: right;
    float:left;
    width:25%;
    overflow:hidden;
    padding-top: 1rem;
    padding-bottom: 1rem;
    font-size: 18px;
    border-bottom: 1px solid #e4e4e4;
}
.total {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
    font-weight: 400 !important;
    border-bottom: 1px solid #e4e4e4 !important;
    font-size: 18px !important;
    color: green;
}
.pricing-right{
    color: black;
    text-align: right;
    float:left;
    width:25%;
    overflow:hidden;
    padding-top: .5rem;
    padding-bottom: .5rem;
    border-bottom: 1px solid #e4e4e4;
}
.main-content-group {
    width: 100%;
    padding-right: 10px;
    padding-left: 10px;
    margin-bottom: 20px;
}

.nice-dates-navigation, .nice-dates-day {
    color: #111;
  }

  .nice-dates-popover {
    box-shadow: none;
    border: 1px solid #ddd;
    border-radius: 2px;
    max-width: 480px;
    transition: none;
  }
.summary-container {
    vertical-align: top;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    width: 100%;
    background: #eee;
    margin: 0px auto;
    position: relative;
    text-align:center;
    padding-bottom: 10px;
    padding-top: 10px;
}
.booking-container {
   background-color: #fbfaf8e0;
   min-height: 500px;
   height: auto;
}

.left-one {
    display: inline-block;
    text-align: center;
    width: 80px;
    font-family: 'Montserrat', sans-serif;
    font-weight: 200;
}
.lite-text {
    font-family: 'Montserrat', sans-serif;
    font-weight: 200;
}

.right-one {
    display: inline-block;
    text-align: center;
    vertical-align: text-top;
    width: 90px;
    font-weight: 200;
}
.right-two {
    display: inline-block;
    text-align: center;
    vertical-align: text-top;
    width: 80px;
    font-weight: 200;
}
.left-one-text {
    display: inline-block;
    font-size: 20px;
    text-align: center;
    vertical-align: text-top;
    width: 90px;

}
.right-one-text {
    display: inline-block;
    font-size: 20px;
    text-align: center;
    vertical-align: text-top;
    width: 90px;
}
.right-two-text {
    display: inline-block;
    font-size: 20px;
    text-align: center;
    vertical-align: text-top;
    width: 90px;
}
.show-detail {
    text-align: right;
}

.booking-summary {
    font-family: 'Montserrat', sans-serif;
    background-color: #eee;
    min-height: 200px;
    border-style: solid;
    border-color: white;
    border-width: 15px;
    padding: 10px;
}
.background-container {
    /* margin: 10px; */
    background-color: white;
    ;
}
.summary-header {
    text-align: center;
    border-style: solid;
    border-color: white;
    border-width: 1px;
    padding-bottom: 10px;
    padding-Top: 10px;
}
.btns-container {
    text-align: center;
    margin-top: 10px;
    margin-left: 10px;
    margin-right: 10px;
}
.left-btn {
    float: left;
    height: 55px;
    width: 100px;
}
.left-btn-contact {
    float: left;
    height: 55px;
    width: 100px;
}
.right-btn-contact {
    float: right;
    height: 55px;
    width: 100px;
}
.right-btn {
    float: right;
    padding-top: 15px;
    margin-top: 30px;
}
.center-btn {
    height: 55px;
    width: 100px;
}

.phone-input {
    width: 100%;
    padding-left: 14px;
    padding-right: 14px;
    padding-top: 18.5px;
    padding-bottom: 18.5px;
    background-color: transparent;
    border-style: solid;
    border-width: 1px;
    border-radius: 3px;;
}
.left {
    display: inline-block;
    width: 50%;
}
.right {
    display: inline-block;
    width: 50%;
}
.summary-heading {
    background-color: transparent;
    height: 30px;
    color: #067746;
}
.check-services {
    background-color: #eee;
    margin-top: 10px;
    padding: 10px;

}

.check {
    padding-right: 10px;
    font-family: 0;
    
}
.MuiContainer-root {
    width: 98% !important;
}

/* }Button Styling */
.left-btn{
    margin-right: 20px;
}

/* Update Code Fields */


