.valet-container > h1 {
    color: #fff;
    font-size: 100px;
}
 
.valet-container > img {
    object-fit: cover;
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: -1;
}
.valet-container > h2 {
    color: #fff;
    font-size: 100px;
}