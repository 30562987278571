.hero-action-container {
    /* width: calc(100% - 60px); */
    padding: 28px 30px 30px;
    border-radius: 6px;
    background-color: rgb(226 225 223);
    box-shadow: rgb(0 0 0 / 8%) 0px 1px 20px;
    /* position: absolute; */
    bottom: -30px;
    z-index: 1;
    max-width: 375px;
}


.hero-action-container > h2 {
    color:rgb(10 10 10);
}
.actionp {
    color: rgb(44, 44, 44);
    font-size: 15px;
    margin: 0px;
    line-height: 24px;
}

@media all and (max-width: 900px) {
    .hero-action-container {
        width: 400px;
    }
}


