.confirm-container {
    background-color: #fbfaf8e0;
    text-align: center;
    padding: 20px;
}
.confirm-container h1 {
    color: whitesmoke;
    font-size: 24px;
    padding-bottom: 10px;
}
/* h2 {
    margin: 10px;
    font-size: 30px;
    color: black;
} */
.submit-heading {
    height: auto;
    width: 100%;
    background-color: #dea80a;
    padding-bottom: 10px;
    padding-top: 10px;
    padding-right: 10px;
    padding-left: 10px;
    margin-bottom: 15px;
}
.submit-heading > p {
    color: whitesmoke;
    padding-left: 50px;
    padding-right: 50px;
}
.location-details {
    height: auto;
    background-color: whitesmoke;
    padding: 10px;
    text-align: left;
}
.checkin-details {
    height: auto;
    background-color: whitesmoke;
    padding-left: 10px;
    text-align: left;
    color: whitesmoke;
}
.checkout-details {
    height: auto;
    background-color: whitesmoke;
    padding-left: 10px;
    text-align: left;
    color: whitesmoke;
}
.location-left {
    display: inline-block;
    vertical-align: top;
    width: 48%;
    margin-right: 4px;
}
.location-text {
    font-size: 14px;
    
}
.location-right {
    display: inline-block;
    width: 48%;
    margin-right: 4px;
}

.checkin-details {
    height: 50px;
    background-color: #dea80a;

}
.checkout-details {
    height: 50px;
    background-color: #f1c23c;

}
.toKnow {
    text-align: left !important;
    font-size: 15px !important;
    color: rgba(0, 0, 0, 0.54);
    padding-bottom: 5px;
}
.toKnowList {
    margin-left: 0px;
    padding-top: 5px;
}
.toKnowList ul {
    list-style-type: none;
}
.toKnowHeading {
    font-size: 20px !important;
    text-align: left;
    font-family: 'Montserrat', sans-serif;
    color: whitesmoke;
    
}
.MuiTypography-body1
 {
    width: 100%!important;
    
}
.left-border {
    border-color: #e7e7e5;;
    border-width: 1px;
    border-style: solid;
    padding: 5px;
    width: 95%;
}
.right-border {
    border-color: #e7e7e5;;
    border-width: 1px;
    border-style: solid;
    padding: 4px;
    width: 105%;
}
.returnText {
    display: inline-block;
    vertical-align: top;
    padding-left: 5px;
}
.confIcons {
    margin-bottom: -6px;
    font-size: 25px !important;
}
.confHeadings {
    display: inline-block;
    padding-bottom: 5px;
    padding-top: 8px;
    padding-left: 5px;
    color: black;
    font-size: 15px;

}
.qrCodeStyle {
    
}
