* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-family: 'Montserrat', sans-serif;
    }
  
  .home,
  .valet,
  .rides,
  .sign-up,
  .faqs {
    /* display: flex; */
    height: 90vh;
    align-items: center;
    justify-content: center;
    font-size: 3rem;
  }
  
  .airport-valet {
    /* background-image: url('/images/img-2.jpg'); */
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    color: #fff;
    font-size: 100px;
  }
  
  .how-it-works {
    /* background-image: url('/images/img-1.jpg'); */
    background-position: center;
    background-size: fill;
    background-repeat: no-repeat;
    color: #fff;
    font-size: 100px;
  }
  
  .faqs {
    /* background-image: url('/images/img-8.jpg'); */
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    color: #fff;
    font-size: 100px;
  }